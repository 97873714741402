globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"36f848b1ebd1aab7ab80262c3185b75da839cfe8"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const ignoreList = [
  'Non-Error',
  'CustomEvent',
  'InvalidStateError',
  'anonymous',
  'tracking',
  'react-masonry',
  'getItem',
  '(type=error) captured as promise rejection',
  'Failed to read the \'localStorage\'',
  'Cannot read properties of undefined (reading \'domInteractive\')',
  'domInteractive',
  'Cannot read properties of undefined (reading \'banner.text\')',
  'banner.text',
  'Cannot read properties of null (reading \'__transferData\')',
  '__transferData',
  'Cannot read properties of null (reading \'removeItem\')',
  'removeItem',
  'The request is not allowed by the user agent or the platform',
  'Unexpected token',
  /CookieSpool/i,
  /banner\.text/i,
  'checkPerfReady',
  'globalThis',
  'Connection closed',
  /^0$/,
  /^Error: 0$/,
  'Failed to fetch',
  'Load failed',
  'The operation is insecure',
  'The operation was aborted',
  'JSONP request failed',
  'Java object is gone',
  'vazgeçildi',
  '%Object%',
  'Cannot read properties of null (reading \'getElementsByTagName\')',
  'Java bridge method invocation error',
  '"undefined" is not valid JSON',
  'mjs.statWebViewLoadSize',
  'Failed to execute \'exitFullscreen\'',
  'javacalljs is not defined',
  'javacalljswithargs is not defined',
  'SafeDKWebAppInterface',
  'Method not found',
  'ReadableStream',
  'IFrameMessageHandler',
  'XHRInterface',
  'Parameter `frame`',
  'jsReceiveMessages',
  'Cannot read properties of null',
];

Sentry.init({
  dsn: "https://574d2a0272108f2a02c435285e26c474@o4504253510647808.ingest.us.sentry.io/4507174661521408",

  // ignoreErrors: ignoreList,
  
  // This is for filtering errors out, instead of ignoring
  beforeSend(event, hint) {
    const err: any = hint?.originalException;
    const errFound = ignoreList.find((ignore) => {
      if (ignore instanceof RegExp) {
        return ignore.test(err?.message);
      }
      
      if(err?.message?.match(ignore)) return true;
      if(err?.message?.toUpperCase().match(ignore.toUpperCase())) return true;
      if(err?.message?.toLowerCase().match(ignore.toLowerCase())) return true;
    });

    if(errFound) return null;
    
    return event;
  },

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
});
